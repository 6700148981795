import { useLocation } from "react-router-dom";
import React from "react";
import Nav from "../components/Nav";
import CheckOutAction from "../components/CheckOutAction";
import PaymentMethods from "../components/PaymentMethods";
const apiBaseUrl = process.env.REACT_APP_API_URL;
const frontEndBaseUrl = process.env.REACT_APP_LANDING_SITE_URL;

const PaymentSelection = () => {
  const { state } = useLocation();
  const { email, orderItems } = state;
  const [selected, setSelected] = React.useState("Bank Transfer 1");
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(true);

  //console.log(email, orderItems);
  let subTotal = orderItems.reduce((accumulator, item) => {
    return accumulator + item.price;
  }, 0);
  const tax = parseFloat(process.env.REACT_APP_TAX) * subTotal;
  const shippingFee = parseFloat(process.env.REACT_APP_SHIPPING_FEE);
  const grandTotal = subTotal + tax + shippingFee;

  const payload = {
    customerEmail: email,
    orderTotalPrice: grandTotal,
    shippingFee: tax,
    tax: shippingFee,
    orderID: Math.random().toString(14).slice(12) + "JUMIA",
    merchantID: "TAKKE-MC-4110",
    orderitems: orderItems,
  };

  const config = {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "Access-Control-Allow-Origin": "*",
    },
    withCredentials: true,
  };

  const handleCheckoutRequest = async (event) => {
    event.preventDefault();
    // console.log(config);
    setIsSubmitted(true);
    setIsDisabled(true);
    const response = await window.fetch(`${apiBaseUrl}new-order`, config);
    const data = await response.json();
    if (response.ok) {
      window.location.href = `${frontEndBaseUrl}?email=${email}`;
      return data;
    } else {
      setIsSubmitted(false);
      return Promise.reject(data);
    }
  };

  const handleChange = (e) => {
    setSelected(e.target.value);

    if (e.target.value === "takke") {
      setIsDisabled(false);
    } else if (e.target.value !== "takke") {
      setIsDisabled(true);
    }
  };

  return (
    <div className="">
      <header className=" mb-12">
        <Nav />
      </header>
      <main className="flex flex-col lg:flex-row px-8 lg:px-0 max-w-4xl mx-auto">
        <PaymentMethods selected={selected} handleChange={handleChange} />
        <CheckOutAction
          checkout={handleCheckoutRequest}
          selected={selected}
          disabled={isDisabled}
          submitted={isSubmitted}
        />
      </main>
    </div>
  );
};

export default PaymentSelection;
