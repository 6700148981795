import React from "react";
import Nav from "../components/Nav";
import Stepper from "../components/Stepper";
import { useLocation, useNavigate } from "react-router-dom";

function Checkout() {
  // get email from router state
  const navigate = useNavigate();

  const { state } = useLocation();
  const { orderItems } = state;

  const subTotal = orderItems.reduce((accumulator, orderItem) => {
    return accumulator + orderItem.price;
  }, 0);
  const tax = parseFloat(process.env.REACT_APP_TAX) * subTotal;
  const shippingFee = parseFloat(process.env.REACT_APP_SHIPPING_FEE);
  const grandTotal = subTotal + tax + shippingFee;

  const handleChange = () => {
    navigate("/pages/paymentSelection", { state });
  };
  return (
    <div className="App">
      <header className=" mb-12">
        <Nav />
      </header>
      <main className="flex flex-col lg:flex-row px-8 lg:px-0 max-w-4xl mx-auto">
        <div className=" w-full lg:flex-[1_1_50%] mr-16">
          <h1 className=" text-4xl font-semibold mb-3">Checkout</h1>
          <Stepper step={1} />
          <div className=" flex gap-4 mb-4 p-8 border-gray-200 border-2 rounded-[32px]">
            <div className="pt-1">
              <svg
                width="20"
                height="28"
                viewBox="0 0 20 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 0C4.48604 0 0 4.36611 0 9.73267C0 16.4779 10.0098 28 10.0098 28C10.0098 28 20 16.1461 20 9.73267C20 4.36611 15.5141 0 10 0ZM13.0172 12.5824C12.1853 13.3919 11.0927 13.7968 10 13.7968C8.90746 13.7968 7.81457 13.3919 6.98297 12.5824C5.31923 10.9633 5.31923 8.32874 6.98297 6.70947C7.78859 5.92505 8.86023 5.49302 10 5.49302C11.1398 5.49302 12.2112 5.92522 13.0172 6.70947C14.6809 8.32874 14.6809 10.9633 13.0172 12.5824Z"
                  fill="#456ED6"
                />
              </svg>
            </div>
            <div>
              <h3 className=" text-2xl mb-6 font-semibold text-black">
                Shipping Address
              </h3>
              <p className=" mb-2 text-lg text-black font-medium">
                Achmad Fiqrih Ar Rachman (Home)
              </p>
              <p className=" mb-4 text-lg text-black font-normal">
                177A Bleecker Street, New York City, NY 10012-1406, on the
                corner of Bleecker Street and Fenno Place in the heart of
                Greenwich Village.
              </p>
              <button className=" bg-white text-blue-500 border-2 border-blue-500 px-3 py-3 rounded-2xl">
                Edit Address
              </button>
            </div>
          </div>
          {/* Products */}
          <div>
            {orderItems.map((item) => (
              <div className="flex gap-4 mb-4 p-8 border-gray-200 border-2 rounded-[32px]">
                <div className="pt-1">
                  <svg
                    width="20"
                    height="28"
                    viewBox="0 0 20 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 0C4.48604 0 0 4.36611 0 9.73267C0 16.4779 10.0098 28 10.0098 28C10.0098 28 20 16.1461 20 9.73267C20 4.36611 15.5141 0 10 0ZM13.0172 12.5824C12.1853 13.3919 11.0927 13.7968 10 13.7968C8.90746 13.7968 7.81457 13.3919 6.98297 12.5824C5.31923 10.9633 5.31923 8.32874 6.98297 6.70947C7.78859 5.92505 8.86023 5.49302 10 5.49302C11.1398 5.49302 12.2112 5.92522 13.0172 6.70947C14.6809 8.32874 14.6809 10.9633 13.0172 12.5824Z"
                      fill="#456ED6"
                    />
                  </svg>
                </div>
                <div>
                  <h3 className=" text-lg font-semibold text-gray-700">
                    Conglomerate Coolors showroom
                  </h3>
                  <span className="inline-block text-lg mb-4 text-gray-500 font-medium">
                    Abuja and Lagos
                  </span>
                  <div className="flex gap-4">
                    <img
                      src={item.image}
                      className="h-48 w-48 object-cover rounded-lg"
                      alt="image of product"
                    />
                    <div>
                      <h3 className="text-2xl font-bold text-blue-500">
                        {item.name}
                      </h3>
                      <p className="text-lg mb-1 text-gray-500 font-medium">
                        {item.price}
                      </p>
                      <span className="text-sm text-gray-500 font-medium">
                        {`Quantity: ${item.quantity}`}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* checkout column */}
        <div className="flex-[1_1_30%] mb-8 lg:mb-0 border-gray-200 border-2 p-4 rounded-[32px] h-fit">
          <div>
            <button
              type="button"
              className="flex items-center justify-center w-full py-4 px-5 mr-2 mb-2 text-lg font-medium text-gray-900 bg-white rounded-[20px] border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-2"
              >
                <path
                  d="M28.1654 24.681C28.4861 25.0017 29.006 25.0017 29.3267 24.681L32.4235 21.5842C32.7441 21.2636 32.7441 20.7436 32.4235 20.423L19.6492 7.64874L16.746 10.552C16.4253 10.8727 15.9054 10.8726 15.5847 10.552C15.2641 10.2313 15.2641 9.71134 15.5847 9.39069L18.488 6.48746L13.4557 1.45519C13.135 1.1345 12.6151 1.13446 12.2944 1.45515L9.19759 4.55192C8.8769 4.87261 8.87694 5.39255 9.19759 5.7132C10.1581 6.6737 10.1581 8.23657 9.19759 9.19711C8.23705 10.1576 6.67422 10.1576 5.71368 9.19711C5.39303 8.87646 4.8731 8.87642 4.55241 9.19711L1.45563 12.2939C1.13494 12.6146 1.13498 13.1345 1.45563 13.4552L6.4879 18.4874L9.39113 15.5842C9.71182 15.2635 10.2318 15.2636 10.5524 15.5842C10.8731 15.9049 10.8731 16.4248 10.5524 16.7455L7.64918 19.6487L20.4234 32.4229C20.7441 32.7436 21.264 32.7436 21.5847 32.4229L24.6815 29.3262C25.0021 29.0055 25.0021 28.4855 24.6815 28.1649C23.721 27.2044 23.7209 25.6415 24.6815 24.681C25.642 23.7204 27.2049 23.7205 28.1654 24.681ZM12.875 14.4229C12.5544 14.7436 12.0344 14.7436 11.7138 14.4229C11.3931 14.1023 11.3931 13.5823 11.7138 13.2616L13.2622 11.7132C13.5829 11.3926 14.1028 11.3926 14.4234 11.7132C14.7441 12.0339 14.7441 12.5539 14.4234 12.8745L12.875 14.4229Z"
                  fill="#456ED6"
                />
              </svg>
              Use Promo Code
            </button>
          </div>
          <div className="mb-6">
            <h4 className="text-base font-medium mb-3">Shopping Summary</h4>
            <ul className="mb-4">
              <li className="flex justify-between text-sm mb-2">
                <span>Total Price Item(s)</span>
                <span>₦{subTotal.toLocaleString()}</span>
              </li>
              <li className="flex justify-between text-sm mb-2">
                <span>Tax</span>
                <span>₦{tax.toLocaleString()}</span>
              </li>
              <li className="flex justify-between text-sm mb-2">
                <span>Shipping Fee</span>
                <span>₦{shippingFee.toLocaleString()}</span>
              </li>
            </ul>
            <hr />
            <div className="mt-4 flex justify-between text-sm mb-2">
              <span className=" font-bold">Grand Total</span>
              <span className=" font-bold">₦{grandTotal.toLocaleString()}</span>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <button
              onClick={handleChange}
              className="w-full h-fit text-white text-lg bg-[#456ED6] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-[20px] px-5 py-4 text-center mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:opacity-75 disabled:pointer-events-none"
            >
              Proceed to Checkout
            </button>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Checkout;
