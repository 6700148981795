import React from "react";
import Stepper from "./Stepper";

const PaymentMethods = ({ handleChange, selected }) => {
  return (
    <div className=" w-full lg:flex-[1_1_50%] mr-16">
      <h1 className=" text-4xl font-semibold mb-3">Checkout</h1>
      <Stepper step={2} />
      <form action="">
        <fieldset>
          <legend className="sr-only">Countries</legend>

          <div className="flex items-center border-gray-200 border-2 rounded-[32px] mb-4">
            <label
              for="paymenttype-option-1"
              className="w-full p-4 flex justify-between items-center text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              <div>
                <input
                  id="paymenttype-option-1"
                  type="radio"
                  name="paymenttype"
                  value="Bank Transfer 1"
                  className="w-4 h-4 mr-2 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                  aria-labelledby="paymenttype-option-1"
                  aria-describedby="paymenttype-option-1"
                  onChange={handleChange}
                  checked={selected === "Bank Transfer 1"}
                />
                Card Payment
              </div>
              <div className="flex">
                <svg
                  width="42"
                  height="25"
                  viewBox="0 0 42 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-2"
                >
                  <path
                    d="M28.7498 0C25.4345 0 22.2551 1.31696 19.9109 3.66117C17.5667 6.00537 16.2498 9.18479 16.2498 12.5C16.2498 15.8152 17.5667 18.9946 19.9109 21.3388C22.2551 23.683 25.4345 25 28.7498 25C32.065 25 35.2444 23.683 37.5886 21.3388C39.9328 18.9946 41.2498 15.8152 41.2498 12.5C41.2498 9.18479 39.9328 6.00537 37.5886 3.66117C35.2444 1.31696 32.065 0 28.7498 0Z"
                    fill="#FFC107"
                  />
                  <path
                    d="M18.7675 20C18.1875 19.2288 17.6887 18.395 17.2975 17.5H23.9538C24.3013 16.705 24.5737 15.87 24.75 15H16.5025C16.3375 14.1925 16.25 13.3575 16.25 12.5H25C25 11.6425 24.9125 10.8075 24.7487 10H16.5013C16.6788 9.13 16.95 8.295 17.2975 7.5H23.9538C23.5625 6.605 23.065 5.77125 22.4837 5H18.7675C19.3137 4.275 19.93 3.5975 20.6188 3.00625C18.4338 1.13625 15.6012 0 12.5 0C5.59625 0 0 5.59625 0 12.5C0 19.4037 5.59625 25 12.5 25C16.5863 25 20.2025 23.0312 22.4825 20H18.7675Z"
                    fill="#FF3D00"
                  />
                </svg>
                <svg
                  width="59"
                  height="20"
                  viewBox="0 0 59 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.8384 0L17.6598 19.7799H22.7403L25.9169 0H20.8384ZM36.1631 8.05722C34.3877 7.18047 33.2993 6.58874 33.2993 5.69202C33.321 4.8767 34.2197 4.04142 36.2265 4.04142C37.8771 3.99996 39.0903 4.38786 40.0107 4.77381L40.4717 4.97959L41.1624 0.841148C40.1589 0.453243 38.5678 0.0258304 36.6024 0.0258304C31.5853 0.0258304 28.0525 2.63436 28.0306 6.36515C27.9891 9.11826 30.5599 10.646 32.4838 11.5625C34.4491 12.5026 35.1181 13.1121 35.1181 13.9474C35.0964 15.2299 33.5289 15.8216 32.0663 15.8216C30.0398 15.8216 28.9511 15.5168 27.2986 14.8022L26.6296 14.4975L25.9191 18.7983C27.1126 19.3288 29.3074 19.7957 31.5855 19.8196C36.9174 19.8196 40.3869 17.2506 40.4305 13.2745C40.4479 11.0934 39.0922 9.42281 36.1631 8.05722ZM54.1873 0.0612134H50.2566C49.0454 0.0612134 48.125 0.409612 47.6005 1.65256L40.0539 19.7799H45.3858L46.8542 15.8553H52.8196L53.5815 19.7957H58.2841L54.1873 0.0612134ZM48.3329 11.8946C48.4358 11.9046 50.3795 5.48797 50.3795 5.48797L51.9272 11.8946C51.9272 11.8946 49.3365 11.8946 48.3329 11.8946ZM13.4145 0L8.43691 13.4386L7.89467 10.7884C6.97429 7.73053 4.08877 4.40761 0.86853 2.75701L5.42658 19.7601H10.8021L18.7901 0.00216951H13.4145V0Z"
                    fill="#456ED6"
                  />
                </svg>
              </div>
            </label>
          </div>

          <div className="flex items-center border-gray-200 border-2 rounded-[32px] mb-4">
            <label
              for="paymenttype-option-2"
              className="w-full flex justify-between items-center p-4 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              <div>
                <input
                  id="paymenttype-option-2"
                  type="radio"
                  name="paymenttype"
                  value="Bank Transfer 2"
                  className="w-4 h-4 mr-2 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                  aria-labelledby="country-option-2"
                  aria-describedby="country-option-2"
                  onChange={handleChange}
                  checked={selected === "Bank Transfer 2"}
                />
                Bank Transfer
              </div>
              <div className="flex">
                <svg
                  width="37"
                  height="32"
                  viewBox="0 0 37 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.9">
                    <path
                      d="M15.0844 13.216L15.0844 10.843C15.0844 9.94923 15.8121 9.22155 16.7068 9.22063L28.3899 9.22063L28.3899 6.06401C28.3899 5.67127 28.6267 5.31523 28.9901 5.16474C29.3534 5.01425 29.7737 5.09867 30.0518 5.37671L36.0163 11.3413C36.3962 11.7212 36.3962 12.3378 36.0163 12.7177L30.0518 18.6822C29.7737 18.9603 29.3544 19.0438 28.991 18.8933C28.6276 18.7428 28.3899 18.3895 28.3909 17.994L28.3899 14.8383L16.7068 14.8383C15.8158 14.8374 15.0853 14.1143 15.0844 13.216Z"
                      fill="#456ED6"
                    />
                    <path
                      d="M6.65283 26.024L0.688278 20.0595C0.308382 19.6796 0.308381 19.063 0.688278 18.6831L6.65283 12.7185C6.93087 12.4405 7.35022 12.357 7.7136 12.5075C8.07698 12.6579 8.31464 13.0131 8.31373 13.4067L8.31464 16.5624L19.9978 16.5624C20.8916 16.5624 21.6193 17.2901 21.6202 18.1848L21.6202 20.5578C21.6202 21.4515 20.8925 22.1792 19.9978 22.1801L8.31464 22.1801L8.31464 25.3367C8.31464 25.7295 8.07789 26.0855 7.71452 26.236C7.35114 26.3865 6.93087 26.3021 6.65283 26.024Z"
                      fill="#456ED6"
                    />
                  </g>
                </svg>
              </div>
            </label>
          </div>

          <div className="flex items-center border-gray-200 border-2 rounded-[32px] mb-4">
            <label
              for="paymenttype-option-3"
              className="w-full flex justify-between items-center p-4 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              <div>
                <input
                  id="paymenttype-option-3"
                  type="radio"
                  name="paymenttype"
                  value="takke"
                  className="w-4 h-4 mr-2 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:bg-gray-700 dark:border-gray-600"
                  aria-labelledby="country-option-3"
                  aria-describedby="country-option-3"
                  onChange={handleChange}
                  checked={selected === "takke"}
                />
                Takke
              </div>
              <div className="flex">
                <svg
                  className="h-4 w-auto"
                  width="120"
                  height="25"
                  viewBox="0 0 120 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.2667 24.9009H0.00201416V12.6362H12.2667V24.9009ZM2.79852 22.1024H9.46619V15.4347H2.79852V22.1024Z"
                    fill="#0AC254"
                  />
                  <path
                    d="M18.383 24.9008H0V6.51782H18.383V24.9008ZM2.7985 22.1023H15.5845V9.31633H2.7985V22.1023Z"
                    fill="#0AC254"
                  />
                  <path
                    d="M24.9029 24.9009H0V0H24.9029V24.9009ZM2.7985 22.1024H22.1044V2.7965H2.7985V22.1024Z"
                    fill="#0AC254"
                  />
                  <path
                    d="M37.8367 6.28008H31.2889V2.65662H48.008V6.28008H41.4602V22.2441H37.8367V6.28008Z"
                    fill="#252122"
                  />
                  <path
                    d="M52.0969 22.2441H48.4654V11.0162C48.4654 6.39794 52.2087 2.65662 56.8249 2.65662C61.4412 2.65662 65.1845 6.39994 65.1845 11.0162V22.2441H61.553V18.6207H52.0969V22.2441ZM52.0969 11.0162V14.9952H61.553V11.0162C61.553 8.40543 59.4357 6.28808 56.8249 6.28808C54.2142 6.28808 52.0969 8.40543 52.0969 11.0162Z"
                    fill="#252122"
                  />
                  <path
                    d="M67.5875 22.2442V2.65666H71.211V16.5353L73.8038 12.4504L75.9471 9.06665L80.016 2.65466H84.3086L78.0924 12.4504L84.3066 22.2442H80.016L75.9471 15.8322L71.8782 22.2442H67.5875Z"
                    fill="#252122"
                  />
                  <path
                    d="M85.4352 22.2442V2.65666H89.0586V16.5353L91.6494 12.4504L93.7927 9.06665L97.8616 2.65466H102.154L95.94 12.4504L102.154 22.2442H97.8616L93.7927 15.8322L89.7238 22.2442H85.4352Z"
                    fill="#252122"
                  />
                  <path
                    d="M106.904 22.2441H103.281V2.65662H120V6.28008H106.904V10.6386H120V14.2621H106.904V18.6207H120V22.2441H106.904Z"
                    fill="#252122"
                  />
                </svg>
              </div>
            </label>
          </div>

          <div className="flex items-center border-gray-200 border-2 rounded-[32px] mb-4">
            <label
              for="paymenttype-option-4"
              className="w-full flex justify-between items-center p-4 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              <div>
                <input
                  id="paymenttype-option-4"
                  type="radio"
                  name="paymenttype"
                  value="Bank Transfer 3"
                  className="w-4 h-4 mr-2 border-gray-300 focus:ring-2 focus:ring:blue-300 dark:focus-ring-blue-600 dark:bg-gray-700 dark:border-gray-600"
                  aria-labelledby="country-option-4"
                  aria-describedby="country-option-4"
                  onChange={handleChange}
                  checked={selected === "Bank Transfer 3"}
                />
                Pay on Delivery
              </div>
              <div className="flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className=" h-6 w-6"
                  viewBox="0 0 20 20"
                  fill="rgba(69, 110, 214, 1)"
                >
                  <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z" />
                  <path
                    fillRule="evenodd"
                    d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </label>
          </div>
        </fieldset>
      </form>
    </div>
  );
};

export default PaymentMethods;
