import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import Checkout from "./pages/Checkout";
import PaymentSelection from "./pages/paymentSelection";
import "flowbite";
import Login from "./pages/auth";

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/home" element={<App />} />
      <Route path="/pages/checkout" element={<Checkout />} />
      <Route path="/pages/paymentSelection" element={<PaymentSelection />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);
