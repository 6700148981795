import React from "react";
import { useLocation } from "react-router-dom";

const CheckOutAction = ({ checkout, selected, disabled, submitted }) => {
  const { state } = useLocation();
  const { orderItems } = state;

  const subTotal = orderItems.reduce((accumulator, orderItem) => {
    return accumulator + orderItem.price;
  }, 0);
  const tax = parseFloat(process.env.REACT_APP_TAX) * subTotal;
  const shippingFee = parseFloat(process.env.REACT_APP_SHIPPING_FEE);
  const grandTotal = subTotal + tax + shippingFee;

  return (
    <div className="flex-[1_1_30%] mb-8 lg:mb-0 border-gray-200 border-2 p-4 rounded-[32px]">
      <div>
        <button
          type="button"
          className="flex items-center justify-center w-full py-4 px-5 mr-2 mb-2 text-lg font-medium text-gray-900 bg-white rounded-[20px] border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mr-2"
          >
            <path
              d="M28.1654 24.681C28.4861 25.0017 29.006 25.0017 29.3267 24.681L32.4235 21.5842C32.7441 21.2636 32.7441 20.7436 32.4235 20.423L19.6492 7.64874L16.746 10.552C16.4253 10.8727 15.9054 10.8726 15.5847 10.552C15.2641 10.2313 15.2641 9.71134 15.5847 9.39069L18.488 6.48746L13.4557 1.45519C13.135 1.1345 12.6151 1.13446 12.2944 1.45515L9.19759 4.55192C8.8769 4.87261 8.87694 5.39255 9.19759 5.7132C10.1581 6.6737 10.1581 8.23657 9.19759 9.19711C8.23705 10.1576 6.67422 10.1576 5.71368 9.19711C5.39303 8.87646 4.8731 8.87642 4.55241 9.19711L1.45563 12.2939C1.13494 12.6146 1.13498 13.1345 1.45563 13.4552L6.4879 18.4874L9.39113 15.5842C9.71182 15.2635 10.2318 15.2636 10.5524 15.5842C10.8731 15.9049 10.8731 16.4248 10.5524 16.7455L7.64918 19.6487L20.4234 32.4229C20.7441 32.7436 21.264 32.7436 21.5847 32.4229L24.6815 29.3262C25.0021 29.0055 25.0021 28.4855 24.6815 28.1649C23.721 27.2044 23.7209 25.6415 24.6815 24.681C25.642 23.7204 27.2049 23.7205 28.1654 24.681ZM12.875 14.4229C12.5544 14.7436 12.0344 14.7436 11.7138 14.4229C11.3931 14.1023 11.3931 13.5823 11.7138 13.2616L13.2622 11.7132C13.5829 11.3926 14.1028 11.3926 14.4234 11.7132C14.7441 12.0339 14.7441 12.5539 14.4234 12.8745L12.875 14.4229Z"
              fill="#456ED6"
            />
          </svg>
          Use Promo Code
        </button>
      </div>
      <div className="mb-6">
        <h4 className="text-base font-medium mb-3">Shopping Summary</h4>
        <ul className="mb-4">
          <li className="flex justify-between text-sm mb-2">
            <span>Total Price Item(s)</span>
            <span>₦{subTotal.toLocaleString()}</span>
          </li>
          <li className="flex justify-between text-sm mb-2">
            <span>Tax</span>
            <span>₦{tax.toLocaleString()}</span>
          </li>
          <li className="flex justify-between text-sm mb-2">
            <span>Shipping Fee</span>
            <span>₦{shippingFee.toLocaleString()}</span>
          </li>
        </ul>
        <hr />
        <div className="mt-4 flex justify-between text-sm mb-2">
          <span className=" font-bold">Grand Total</span>
          <span className=" font-bold">₦{grandTotal.toLocaleString()}</span>
        </div>
      </div>
      <form onSubmit={checkout} className="flex flex-col items-center">
        <button
          type="submit"
          className="w-full h-fit text-white text-lg bg-[#456ED6] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-[20px] px-5 py-4 text-center mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:opacity-75 disabled:pointer-events-none"
          disabled={disabled}
        >
          <span className="mr-3">
            Checkout {`${selected === "takke" ? "with Takke" : ""}`}
          </span>
          {disabled && submitted && (
            <svg
              role="status"
              className="inline mr-3 w-4 h-4 text-white animate-spin"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB"
              />

              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor"
              />
            </svg>
          )}
        </button>
      </form>
    </div>
  );
};

export default CheckOutAction;
