import React from "react";

const Stepper = ({ step }) => {
  return (
    <div className="mb-8 w-full">
      <div className="mb-2">{`${step} of 2 step`}</div>
      <div className="flex gap-2">
        <div className="flex-1">
          <div className="stepper-bg w-full h-2 bg-blue-500 rounded-lg mb-2"></div>
          <div className="stepper-description flex items-center">
            <div className="flex justify-center items-center bg-blue-500 rounded-full text-white p-1 w-6 h-6 mr-2">
              1
            </div>
            <div className="text-base text-black font-medium">
              Set the order
            </div>
          </div>
        </div>
        {/* step 2 */}
        <div className="flex-1">
          <div
            className={`w-full h-2 rounded-lg mb-2 ${
              step === 2 ? "bg-blue-500" : "bg-blue-200"
            }`}
          ></div>
          <div className="flex items-center">
            <div
              className={`flex justify-center items-center rounded-full p-1 w-6 h-6 mr-2 ${
                step === 2
                  ? "bg-blue-500 text-white "
                  : "bg-white text-blue-500 border-2 border-blue-500"
              }`}
            >
              2
            </div>
            <div className="text-base text-black font-medium">
              Set the payment
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stepper;
