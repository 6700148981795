import React from "react";
import { Link } from "react-router-dom";
import Nav from "./components/Nav";
import Stepper from "./components/Stepper";
import SamsungOne from "./images/samsung.jpg";
import SamsungTwo from "./images/samsung-two.jpg";
import Shirt from "./images/shirt.jpg";
import Shirts from "./images/shirts.jpg";
import Nike from "./images/nike.jpg";
import NikeTwo from "./images/nike-two.jpg";
import { useLocation, useNavigate } from "react-router-dom";

const products = [
  {
    id: 1,
    name: "Samsung Galaxy S22 Ultra",
    price: 690000,
    image: SamsungOne,
    quantity: 1,
  },
  {
    id: 2,
    name: "Guess Shirt Pack",
    price: 24000,
    image: Shirts,
    quantity: 1,
  },
  {
    id: 3,
    name: "Samsung Galaxy S20 Ultra",
    price: 500000,
    image: SamsungTwo,
    quantity: 1,
  },
  {
    id: 4,
    name: "Nike Air Max 2010",
    price: 8000,
    image: NikeTwo,
    quantity: 1,
  },
  {
    id: 5,
    name: "ASOS Top",
    price: 12000,
    image: Shirt,
    quantity: 1,
  },
  {
    id: 6,
    name: "Nike Air Max 2020",
    price: 72900,
    image: Nike,
    quantity: 1,
  },
];
const getFormattedPrice = (price) => `₦${price.toLocaleString()}`;
function App() {
  const [selectedProducts, setSelectedProducts] = React.useState([]);
  const navigate = useNavigate();
  const { state } = useLocation();

  console.log(state);

  const handleChange = (e) => {
    const { value, checked } = e.target;
    const obj = products.find((product) => product.id === parseInt(value));

    if (checked) {
      // Case 1 : The user checks the box
      setSelectedProducts([...selectedProducts, obj]);
    } else {
      // Case 2  : The user unchecks the box
      setSelectedProducts(selectedProducts.filter((e) => e !== obj));
    }
  };

  const handleAddToCart = (event) => {
    event.preventDefault();
    navigate("/pages/checkout", {
      state: { ...state, orderItems: selectedProducts },
    });
  };

  return (
    <div className='App'>
      <header className=' mb-12'>
        <Nav />
      </header>
      <main className='px-8 lg:px-0 max-w-4xl mx-auto'>
        <form onSubmit={handleAddToCart}>
          <div className='grid grid-cols-2 gap-4 mb-8'>
            {products.map((product, index) => (
              <div key={index} className='col-span-1 flex flex-col'>
                <img className='h-48 w-full mb-4 object-cover scale-100 hover:scale-75 ease-in duration-150' src={product.image} alt='' />
                <div className='flex justify-between'>
                  <div>
                    <h3 className='text-lg'>{product.name}</h3>
                    <span className='text-sm font-bold'>{getFormattedPrice(product.price)}</span>
                  </div>
                  <div className=''>
                    <span className='mr-2'>Add to Cart</span>
                    <input type='checkbox' id={`merchant-checkbox-${product.id}`} name={product.name} value={product.id} onChange={handleChange} />
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* SUbmit button */}
          <div className='mb-8'>
            <button
              type='submit'
              className='text-lg w-full py-4 px-12 font-semibold rounded-md bg-blue-700 text-white hover:bg-blue-900 ease-in duration-75'
            >
              Proceed to Checkout
            </button>
          </div>
        </form>
      </main>
    </div>
  );
}

export default App;
